
import { Options } from "vue-class-component";
import NodesConfigMixins from "../NodesConfigMixins";
//import { codemirror } from 'vue-codemirror'
// 引入主题 可以从 codemirror/theme/ 下引入多个
//import 'codemirror/theme/idea.css'
// 引入语言模式 可以从 codemirror/mode/ 下引入多个
//import "codemirror/mode/javascript/javascript.js"
@Options({
    name: "flowable-nodes-trigger-node-config",
    components: {
        // codemirror
    },
})
export default class TriggerNodeConfig extends NodesConfigMixins {
    private cmOptions: any = {
        tabSize: 4, // tab
        indentUnit: 4,
        styleActiveLine: true, // 高亮选中行
        lineNumbers: true, // 显示行号
        styleSelectedText: true,
        line: true,
        foldGutter: true, // 块槽
        gutters: ["CodeMirror-linenumbers", "lock", "warn"],
        highlightSelectionMatches: { showToken: /w/, annotateScrollbar: true }, // 可以启用该选项来突出显示当前选中的内容的所有实例
        mode: "javascript",
        // hint.js options
        hintOptions: {
            // 当匹配只有一项的时候是否自动补全
            completeSingle: false,
        },
        // 快捷键 可提供三种模式 sublime、emacs、vim
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: false,
        // scrollbarStyle:null,
        // readOnly:true,  //是否只读
        theme: "material", // 主题 material
        extraKeys: { Ctrl: "autocomplete" }, // 可以用于为编辑器指定额外的键绑定，以及keyMap定义的键绑定
        lastLineBefore: 0,
    };
    public addItem(items: any) {
        if (items.length > 0 && (items[items.length - 1].name.trim() === "" || items[items.length - 1].value.trim() === "")) {
            this.toast.warning("请完善之前项后在添加");
            return;
        }
        items.push({ name: "", value: "", isField: true });
    }
    public delItem(items: any, index: any) {
        items.splice(index, 1);
    }
    public onCmCodeChange() {}
    public onCmReady() {}
}
